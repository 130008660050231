<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

      <v-tabs v-model="tab">
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <RunCampaignRules></RunCampaignRules>
        </v-tab-item>
      </v-tabs-items>

    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "BingRules",

  data() {
    return {
      tab: null,
      items: ["Run Campaign Import Rules"],
    };
  },

  components: {
    RunCampaignRules: () => import("@/components/bingRules/RunCampaignRules.vue"),
  },
}
</script>

<style scoped>
</style>